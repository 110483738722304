.o-downloadableItem__container {
  width: 600px;
  display: flex;
  justify-content: space-between;
  color: var(--color-element-orange);
  font-family: 'VP Pixel' !important;
  font-weight: normal !important;
  font-size: var(--font-big);
}

.o-downloadableItem {
  width: 100%;
  padding: 18px 18px 18px 18px;

  &__header {
    justify-content: space-between;

    &-content {
      display: flex;
    }

    &-title {
      margin-left: 18px;
    }

    &-req {
      display: flex;
      flex-direction: column;
      color: var(--color-element-white);
    }
  }

  &__name {
    font-size: var(--font-big);
    font-weight: normal;
    margin: 0;
    line-height: 0.8em;
  }

  &__rarity {
    font-size: var(--font-big);
    font-weight: normal;
    margin: 0;
  }

  &__content {
    padding: 6px 0 0 0;
  }

  &__description {
    margin-bottom: 8px;
  }

  &__flavor {
    margin: 12px 0 8px 0;
    color: var(--color-element-red);
  }

  &__enchants {
    display: flex;

    flex-direction: column;

    &__base {
      // Override the color
      --color-element-light-blue: #15c300;

      margin-right: 60px;
      width: 185px;
      min-width: 185px;
    }

    &__fixed {
      margin-bottom: 8px;

      &-label {
        margin-bottom: 2px;
        display: inline-block;
        margin-top: 12px;
        color: var(--color-element-orange);

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &__slotsContainer {
      width: 65%;

      .m-enchantSlot {
        color: var(--color-element-white);
      }
    }

    &__slots {
      list-style: none;

      &-replace {
        color: var(--color-element-white);
        padding-top: 5px;
        em {
          font-style: normal;
          font-weight: bold;
        }

        &-chance {
          font-weight: 600 !important;
          color: var(--color-element-light-beige);
        }
      }
    }
  }

  &__set {
    padding: 6px 0;
    color: var(--color-element-beige);

    &-name {
      color: var(--color-item-set);
    }
  }

  &__setInfo {
    width: 60%;
    margin-right: 20px;

    &-itemTypes {
      color: var(--color-element-orange);
      font-weight: normal;
    }

    &-bonuses {
      margin-top: 4px;
    }

    &-bonus {
      list-style: none;
      color: #737373;
      margin-top: 2px;

      &-count {
        color: var(--color-element-orange);
        padding-right: 5px;
        font-style: normal;
      }

    }
  }
}
