@import "src/assets/styles/responsive.scss";

.o-footer {
  background-color: var(--color-background-black);
  border-top: 1px solid var(--color-element-darker-brown);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Kufam";
  font-size: var(--font-small);
  text-align: center;
  line-height: 1em;

  &__separator {
    display: inline-block;
    padding: 0 1em;
    margin-top: -1px;

    @include mediaMobile {
      display: none;
    }
  }

  @include mediaMobile {
    .o-footer-item {
      &.made-with,
      &.source-code {
        display: none;
      }
    }
  }
}