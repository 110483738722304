:root {
  /* = FONT SIZES = */
  --font-small: 12px;
  --font-normal: 14px;
  --font-big: 16px;
  --font-title: 28px;

  /* = COLORS = */
  /* == BACKGROUND == */
  --color-background-light-brown: #4f4235;
  --color-background-brown: #50452e;
  --color-background-mid-brown: #1b1917;
  --color-background-dark-brown: #171614;
  --color-background-darker-brown: #151412;
  --color-background-darkest-brown: #121110;
  --color-background-light-black: #191715;
  --color-background-black: #11100f;

  /* == ELEMENTS == */
  --color-element-white: #DEDEDE;
  --color-element-grey: #6e6e6e;
  --color-element-light-beige: #FFEEDE;
  --color-element-beige: #DECFB0;
  --color-element-mid-beige: #696255;
  --color-element-dark-beige: #8e847b;
  --color-element-brown: #50452e;
  --color-element-mid-brown: #332c1d;
  --color-element-dark-brown: #211c15;
  --color-element-darker-brown: #1E1918;
  --color-element-darkest-brown: #121110;
  --color-element-yellow: #f7c200;
  --color-element-light-orange: #dc9d63;
  --color-element-orange: #cd7f3c;
  --color-element-red: #aa2d2d;
  --color-element-light-green: #29d52b;
  --color-element-green: #19901a;
  --color-element-transparent-blue: #1c1d1d;
  --color-element-light-blue: #00b9f8;
  --color-element-dark-blue: #637793;
  --color-element-darkest-blue: #1b1f25;
  --color-element-purple: #8833d0;

  /* == CLASSES */
  --color-class-warlock: #511d7d;
  --color-class-templar: #0b5485;
  --color-class-berserker:#85230b;
  --color-class-warden: #3d7114;
  --color-class-anyClass: #544039;

  /* == ITEMS == */
  /* -- Rarity -- */
  --color-item-ordinary: #FFEEDE;
  --color-item-enchanted: #345A8C;
  --color-item-rare: #8833D0;
  --color-item-unique: #DA119E;
  --color-item-legendary: #AA2D2D;
  --color-item-trueLegendary: #AA2D2D;
  --color-item-set: #f7c200;
  --color-item-mythical: #FFEEDE;

  /* == ENCHANTS == */
  /* -- Category -- */
  --color-enchant-minor: #FFEEDE;
  --color-enchant-major: #637793;
  --color-enchant-epic: #8833d0;
  --color-enchant-legendary:#cd7f3c;

  --color-enchant-dim-minor: rgba(222, 207, 176, 0.25);
  --color-enchant-dim-major: rgba(99, 119, 147, 0.45);
  --color-enchant-dim-epic: rgba(136, 51, 208, 0.35);
  --color-enchant-dim-legendary: rgba(205, 127, 60, 0.35);

  /* == SKILLS == */
  /* -- Damage Types -- */
  --color-damage-type-physical: #9DB1AB;
  --color-damage-type-lightning: #00F0D8;
  --color-damage-type-fire: #F04200;
  --color-damage-type-holy: #ffec88;
  --color-damage-type-ethereal: #DEDEDE;
  --color-damage-type-poison: #808625;
  --color-damage-type-shadow: #A200E1;
  --color-damage-type-frost: #00ABE5;

  /* IN GAME COLORS, used as reference
  --color-damage-type-physical: #9DB1AB;
  --color-damage-type-lightning: #00F0D8;
  --color-damage-type-fire: #F04200;
  --color-damage-type-holy: #FDD500;
  --color-damage-type-ethereal: #DEDEDE;
  --color-damage-type-poison: #808625;
  --color-damage-type-shadow: #A200E1;
  --color-damage-type-frost: #00ABE5; */

}