@import "src/assets/styles/responsive.scss";

.o-enchantsPool {

  &__possibleEnchants {
    width: 30%;
    background-color: var(--color-background-dark-brown);

    @include mediaUpToSmallTablet {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    padding: 12px;
     text-align: center;
     font-family: 'Philosopher';
     font-weight: bold;
     font-size: var(--font-big);
     color: var(--color-element-mid-beige);
     border-bottom: 2px solid var(--color-enchant-dim-minor);
   }

  &__enchantsPoolMobile {
    z-index: 9998;

    .drawer-handle {
      opacity: 0.8;
      top: 185px;
      top: 105px;

      right: -48px !important;
      width: 50px;
      height: 48px;
      background-color: var(--color-background-mid-brown);
      border: 1px solid var(--color-element-mid-beige);

      &::after {
        content: '';
        display: block;
        background-image: url(/assets/images/game/items/categories/enchants.png);
        background-repeat: no-repeat;
        background-position: 100%;
        background-size: 100%;
        width: 68%;
        height: 68%;
        border-radius: 5px;
      }

      .drawer-handle-icon {
        display: none
      }
    }
  }
}