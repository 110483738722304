@import "src/assets/styles/responsive.scss";

.o-enchant {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 50px;

  border-bottom: 1px solid var(--color-element-darkest-blue);
  background: var(--color-background-darkest-brown);
  color: var(--color-element-beige);

  padding: 18px;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-content {
      display: flex;
    }

    &-title {
      margin-left: 18px;
      color: var(--color-element-light-beige);
    }

    &-name {
      font-size: var(--font-big);
      font-family: 'Philosopher';
      margin: 0 0 4px 0;
      display: inline-flex;
    }

    &-category {
      font-size: var(--font-normal);
      font-weight: normal;
      margin: 0;
    }

    &-affixes {
      color: var(--color-element-beige);

      .a-badge {
        padding: 4px 12px;
        border-radius: 6px;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(51, 44, 29, 0.5);
    margin: 18px 10px 18px 50px;
    padding: 18px 0 0 0;

    &-main {
      width: 65%;
      margin-right: 60px;

      @include mediaUpToSmallTablet {
        width: 100%;
      }
    }

    &-sub {
      width: 30%;

      @include mediaUpToSmallTablet {
        width: 100%;
        margin-top: 15px;
        padding-top: 20px;
        border-top: 1px solid rgba(51, 44, 29, 0.5);
      }
    }

    @include mediaUpToSmallTablet {
      flex-direction: column;
    }
  }

  &__itemTypes {
    &-title {
      font-family: 'Philosopher';
      font-size: var(--font-big);
      margin: 0 0 12px 0;
    }
  }

  &__itemType {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-bottom: 1px dashed #435063;

    a {
      color: var(--color-item-set);
    }
  }

  &__description {
    font-weight: normal;

    &-enchant {
      &-amount {
        color: var(--color-element-light-beige);
        font-weight: bold;
      }

      &-skill {
        color: var(--color-element-yellow);
      }
    }
  }

  &__ranges {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 8px;

    &-column {
      width: 45%;

      &.full {
        width: 100%;
      }

      @include mediaUpToSmallTablet {
        width: 100%;
      }
    }

    @include mediaUpToSmallTablet {
      flex-direction: column;
    }
  }

  &__rolls {
    margin-top: 10px;

    &-rarity {
      display: block;
      border-bottom: 1px solid var(--color-element-mid-brown);
      padding: 4px 0;
      margin-bottom: 10px;
    }
  }

  &__roll {
    list-style: none;
    display: flex;
    justify-content: space-between;

    &-header {
      color: var(--color-element-grey);
    }

    &-roll {
      font-style: normal;
    }
  }
}