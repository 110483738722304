@import "src/assets/styles/responsive.scss";

.p-layout {
  background-image: url('/assets/images/textures/body_background.png');
  background-repeat: no-repeat;
  background-size: 100%;

  min-height: 100vh;
}

.p-wrapper {
  min-height: calc(100vh - 40px - 60px);
  max-width: 1440px;
  margin: 0 auto;
}

.p-content {
  background-image: url('/assets/images/textures/content_background.png');
  background-repeat: repeat;
  margin: 15px 35px;
  min-height: calc(100vh - 115px);
  border-radius: 4px;
  position: relative;

  @include mediaUpToTablet {
    margin: 6px 6px;
    min-height: calc(100vh - 110px);
  }
}