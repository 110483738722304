*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', 'Helvetica', 'Ubuntu', sans-serif;
  font-size: var(--font-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--color-element-light-beige);
  background-image: url('/assets/images/textures/body.png');
  background-color: var(--colorbackground-light-brown);
  background-repeat: repeat;
}

h1, ul, li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--color-element-light-orange);
}

a:hover {
  text-decoration: none;
  color: var(--color-element-orange);
}

/* Vendor styles */
.tippy-popper {
  top: -3px !important;
}

.tippy-tooltip {
  background-color: #544039 !important;
}

.tippy-popper[x-placement^=right] [x-arrow] {
  border-right-color: #544039 !important;
}

.tippy-popper[x-placement^=bottom] [x-arrow] {
  border-bottom-color: #544039 !important;
}