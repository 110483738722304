@import "src/assets/styles/responsive.scss";

.m-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-element-darkest-blue);

  &__skills {
    display: flex;
    justify-content: space-between;
    margin: 0 18px;

    width: 100%;

    @include mediaUpToSmallTablet {
      width: 100%;
    }
  }

  &__thead {
    color: var(--color-element-beige);
    font-family: Philosopher;
    font-size: var(--font-normal);
    text-align: center;
    text-transform: uppercase;

    &.reqSkill {
      width: 70px;
      text-align: left;

      @include mediaUpToSmallTablet {
        width: initial;
      }
    }
  }
}