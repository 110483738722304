.a-sortSelect {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &__icon {
    display: inline-flex;
    flex-direction: column;
    margin-left: 5px;

    &-up {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 3.5px 4.2px 3.5px;
      border-color: transparent transparent var(--color-element-beige) transparent;
      opacity: 0.5;

      &.selected {
        opacity: 1;
      }
    }

    &-down {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4.2px 3.5px 0 3.5px;
      border-color: var(--color-element-beige) transparent transparent transparent;
      margin-top: 2px;
      opacity: 0.5;

      &.selected {
        opacity: 1;
      }
    }
  }
}