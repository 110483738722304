@import "src/assets/styles/responsive.scss";

.o-header {
  background-color: var(--color-background-black);
  border-bottom: 1px solid var(--color-element-dark-brown);
  width: 100%;
  background: linear-gradient(90deg, #11100f 80%, #191715 81%);
  // background: linear-gradient(90deg, rgba(17,16,15,1) 73%, rgba(25,23,21,1) 74%);

  // @include mediaUpToDesktop {
  //   background: linear-gradient(90deg, #11100f 80%, #191715 81%);
  // }

  @include mediaUpToSmallTablet {
    background: var(--color-background-black);
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    height: 60px;
    max-width: 1440px;
    margin: 0 auto;
  }

  &__logo {
    display: inline-block;
    color: var(--color-element-beige);
    font-family: Philosopher;
    font-size: var(--font-title);

    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
    @include mediaUpToTablet {
      font-size: 22px;
    }
  }

  &__left {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    cursor: pointer;
  }

  &__main-menu {
    height: 100%;
    display: inline-block;
    padding: 20px 45px 14px 0;
    padding-left: 45px;

    // Temporary solution (good for 4 items on header)
    @media (max-width: 1000px){
      padding: 20px 20px 14px 0;
      padding-left: 20px;
    }

    &-item {
      height: 100%;
      display: inline-block;
      margin: 0 15px;

      // Temporary solution (good for 4 items on header)
      @media (max-width: 1000px){
        margin: 0 8px;
      }

      font-family: "Kufam";
      font-size: var(--font-normal);
      text-transform: capitalize;

      &:first-child {
        margin-left: 0;
      }

      &.current {
        font-weight: 500;

        a {
          border-bottom: 3px solid var(--color-element-light-orange);
        }
      }

      a {
        color: var(--color-element-light-beige);
        text-decoration: none;
        border-bottom: 3px solid transparent;

        &:hover {
          color: #fff7f0;
          border-bottom: 3px solid var(--color-element-light-orange);
        }
      }
    }
  }

  &__sub-menu {
    height: 100%;
    display: inline-block;
    margin-right: 15px;

    &-item {
      position: relative;
      z-index: 2;
      height: 100%;
      display: inline-block;
      padding: 22px 30px 14px 30px;
      transform: skew(25deg, 0deg);

      background: var(--color-background-light-black);
      border-left: 1px solid #2e2624;
      border-right: 1px solid #2e2624;

      font-family: "Kufam";
      font-size: var(--font-normal);
      text-transform: capitalize;

      &:last-child {
        border-right: none;
        z-index: 0;
        transform: initial;
        margin: 0 -40px 0 -20px;
        padding-left: 55px;
        padding-right: 50px;

        a {
          transform: initial;
        }
      }

      &.current {
        font-weight: 500;

        a {
          color: var(--color-light-beige);
          border-bottom: 3px solid var(--color-element-light-orange);
        }
      }

      a {
        display: inline-block;
        transform: skew(-25deg, 0deg);
        border-bottom: 3px solid transparent;

        color: var(--color-element-beige);
        text-decoration: none;

        &:hover {
          color: #fff7f0;
          border-bottom: 3px solid var(--color-element-light-orange);
        }
      }
    }

    &-patches {
      color: var(--color-element-beige);
      transform: skew(-25deg, 0deg);

      select {
        background: transparent;
        border: none;
        outline: none;
        margin-left: 3px;
        padding-right: 5px;

        color: var(--color-element-beige);
        font-family: 'Kufam';
        font-size: var(--font-normal);
      }
    }
  }

  &__menuMobile {
    &-items {
      list-style: none;
      height: 100%;
      background: var(--color-background-black);
      padding: 24px 36px;
      font-family: 'Kufam';
      text-transform: capitalize;
    }

    &-item {
      padding: 8px 0;

      a {
        color: var(--color-element-light-beige);
        text-decoration: none;
        border-bottom: 2px solid var(--color-element-brown);

        &:hover {
          color: #fff7f0;
          border-bottom: 2px solid var(--color-element-light-orange);
        }
      }

      &.current {
        padding-bottom: 12px;
        font-weight: 500;

        a {
          border-bottom: 2px solid var(--color-element-light-orange);
        }
      }

      &.patch {
        margin-top: 12px;
        max-width: 130px;

        .a-dropdown__trigger {
          padding: 0;
        }
      }
    }
  }
}
