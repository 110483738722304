.a-search {
  position: relative;
  margin-right: 1.2em;

  &__input {
    outline: none;
    width: 180px;
    height: 34px;
    padding: 8px 32px 8px 12px;
    border-radius: 3px;
    border: none;

    background-color: var(--color-background-black);
    color: var(--color-element-light-beige);

    font-family: "Nunito Sans";
    font-size: var(--font-normal);

    &::placeholder {
      color: var(--color-element-brown);
    }
  }

  &__iconContainer {
    position: absolute;
    background-color: var(--color-background-black);
    top: 6px;
    right: 10px;
    padding-left: 5px;
    padding-top: 1px;
  }
}