@import "src/assets/styles/responsive.scss";

.o-item__container {
  display: flex;
  justify-content: space-between;
  color: var(--color-element-beige);
}

.o-item {
  width: 100%;
  padding: 18px 18px 0 18px;

  &:hover {
    .o-itemDownloader {
      display: block;

      @include mediaUpToSmallTablet {
        display: none;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &-content {
      display: flex;
    }

    &-title {
      margin-left: 18px;
      color: var(--color-element-light-beige);

      &-link {
        color: inherit;
        border-bottom: 1px solid transparent;

        &:hover {
          color: inherit;
          border-color: var(--color-element-beige);
        }
      }
    }

    &-req {
      display: flex;
      flex-direction: column;
      text-align: right;

      img {
        margin-left: 5px;

        &:last-child {
          margin-left: 10px;
        }
      }

      &-badge {
        margin-bottom: 6px;
        opacity: 0.8;
      }

      @include mediaUpToSmallTablet {
        width: initial;
      }
    }
  }

  &__name {
    font-size: var(--font-big);
    font-family: 'Philosopher';
    margin: 0 0 4px 0;
    display: inline-flex;

    a {
      display: inline-block;
    }

    .o-itemDownloader {
      margin-left: 15px;
      cursor: pointer;
      display: none;
    }
  }

  &__rarity {
    font-size: var(--font-normal);
    font-weight: normal;
    margin: 0;
  }

  &__content {
    border-top: 1px solid rgba(51, 44, 29, 0.5);
    margin: 18px 10px 18px 50px;
    padding: 18px 0 0 0;
  }

  &__description {
    margin-bottom: 8px;
  }

  &__flavor {
    margin: 12px 0 8px 0;
    color: var(--color-element-red);
    font-style: italic;
  }

  &__enchants {
    display: flex;

    @include mediaMobile {
      flex-direction: column;
    }

    &__base {
      margin-right: 60px;
      width: 185px;
      min-width: 185px;

      @include mediaMobile {
        width: 100%;
        margin-right: 0;
      }
    }

    &__fixed {
      margin-bottom: 8px;

      &-label {
        margin-bottom: 2px;
        display: inline-block;
        margin-top: 12px;
        color: var(--color-element-orange);

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &__slotsContainer {
      width: 65%;

      @include mediaMobile {
        width: 100%;
        margin-right: 0;
        margin-top: 12px;
      }
    }

    &__slots {
      list-style: none;

      &-replace {
        padding-top: 5px;
        em {
          font-style: normal;
          font-weight: bold;
        }

        &-chance {
          font-weight: 600 !important;
          color: var(--color-element-light-beige);
        }
      }
    }
  }

  &__set {
    color: var(--color-element-beige);
    background-color: var(--color-background-mid-brown);
    margin: 0 -18px;
    padding: 0px 18px 0px 68px;
  }
}
