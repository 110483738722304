@import "src/assets/styles/responsive.scss";

.o-itemsList {
  position: relative;
  width: 100%;

  &__container {
    display: flex;
  }

  &__items {
    width: 70%;

    @include mediaUpToSmallTablet {
      width: 100%;
    }
  }

  .o-item__container {
    background: var(--color-background-darkest-brown);
    min-height: 50px;
    border-bottom: 1px solid var(--color-element-darkest-blue);

    &:nth-child(2n) {
      background: var(--color-background-darker-brown);
    }
  }

  &__noItem {
    font-size: var(--font-big);
    padding: 34px;
    text-align: center;
  }
}