@import "src/assets/styles/responsive.scss";

.m-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-element-darkest-blue);

  &__items {
    display: flex;
    justify-content: space-between;
    margin: 0 18px;

    width: 70%;

    @include mediaUpToSmallTablet {
      width: 100%;
    }
  }

  &__enchants {
    width: 30%;

    @include mediaUpToSmallTablet {
      display: none;
    }
  }

  &__thead {
    color: var(--color-element-beige);
    font-family: Philosopher;
    font-size: var(--font-normal);
    text-align: center;
    text-transform: uppercase;

    &.req {
      width: 90px;
      text-align: left;

      @include mediaUpToSmallTablet {
        width: initial;
      }
    }
  }
}