.m-tabs {

  &__navigation {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &__navigation-tab {
    cursor: pointer;
    padding: 6px 12px;
    font-family: 'Kufam';
    font-size: var(--font-normal);
    font-weight: normal;
    line-height: 24px;
    width: 100%;
    text-align: center;
    border-bottom: 2px solid white;
    border-right: 1px solid var(--color-element-mid-brown) !important;


    &:hover, &.selected {
      box-shadow: inset 0 0 22px var(--box-shadow-color);
    }

    &:last-child {
      border-right: none;
    }
  }
}