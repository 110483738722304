
.o-itemDownloader {
  &__item {
    background-color: var(--color-background-darkest-brown);
  }

  &__icon {
    display: block;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}
