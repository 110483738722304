@import "src/assets/styles/responsive.scss";

.o-itemFilters {
  display: flex;
  align-items: center;
  position: relative;
  height: 66px;
  padding: 2px 1.2em 0 1.2em;
  border-radius: 4px 4px 0 0;
  background-color: var(--color-background-dark-brown);
  box-shadow: 0 2px 5px 1px rgba(1, 1, 1, 0.5);
  border-bottom: 1px solid #0b0b0b;
  z-index: 1;

  &__setCheckbox {
    margin-left: 1.2em;
  }

  &__classDropdown {
    font-size: var(--font-normal);
    font-family: "Kufam";
    margin-left: auto;
    max-width: 136px;
    color: var(--color-element-beige);
  }

  @include mediaMobile {
    .o-itemFilters__classDropdown {
      display: none;
    }

  }

  @include mediaUpToSmallTablet {
    .o-itemFilters__search {
      margin-left: 35px;
    }
  }

  @include mediaUpToTablet {
    .o-itemFilters__raritySelect {
      display: none;
    }

    .o-itemFilters__search {
      width: 100%;
      margin-right: 0;

      input {
        width: 100%;
      }
    }
  }

  @media (max-width: 1060px) {
    .o-itemFilters__setCheckbox {
      display: none;
    }
  }

}