.a-multiselect {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 34px;

  border-radius: 3px;
  border: 1px solid var(--color-element-brown);
  border-bottom: 0;

  font-size: var(--font-normal);
  font-family: 'Kufam';
  line-height: 1em;

  &__option {
    cursor: pointer;

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 2px 12px 0 12px;
    height: 100%;

    border-right: 1px solid var(--color-element-brown);

    color: var(--color-element-brown);

    &:hover {
      color: var(--color-element-dark-beige);
      opacity: 0.9;
    }

    &:hover {
      .a-multiselect__option-borderBottom {
        background: var(--color-element-dark-beige);
      }
    }

    &-borderBottom {
      position: absolute;
      bottom: 0px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 1px;
      background: var(--color-element-brown);
    }

    &:last-child {
      border-right: 0;
    }
  }
}