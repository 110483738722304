@font-face {
  font-family: 'Kufam';
  src: url('/assets/fonts/Kufam/Kufam-Bold.woff2') format('woff2'),
  url('/assets/fonts/Kufam/Kufam-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kufam';
  src: url('/assets/fonts/Kufam/Kufam-BoldItalic.woff2') format('woff2'),
  url('/assets/fonts/Kufam/Kufam-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Kufam';
  src: url('/assets/fonts/Kufam/Kufam-Italic.woff2') format('woff2'),
  url('/assets/fonts/Kufam/Kufam-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Kufam';
  src: url('/assets/fonts/Kufam/Kufam-MediumItalic.woff2') format('woff2'),
  url('/assets/fonts/Kufam/Kufam-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Kufam';
  src: url('/assets/fonts/Kufam/Kufam-Medium.woff2') format('woff2'),
  url('/assets/fonts/Kufam/Kufam-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kufam';
  src: url('/assets/fonts/Kufam/Kufam-Regular.woff2') format('woff2'),
  url('/assets/fonts/Kufam/Kufam-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Philosopher */

@font-face {
  font-family: 'Philosopher';
  src: url('/assets/fonts/Philosopher/Philosopher-Bold.woff2') format('woff2'),
  url('/assets/fonts/Philosopher/Philosopher-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Philosopher';
  src: url('/assets/fonts/Philosopher/Philosopher-Italic.woff2') format('woff2'),
  url('/assets/fonts/Philosopher/Philosopher-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Philosopher';
  src: url('/assets/fonts/Philosopher/Philosopher-BoldItalic.woff2') format('woff2'),
  url('/assets/fonts/Philosopher/Philosopher-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Philosopher';
  src: url('/assets/fonts/Philosopher/Philosopher-Regular.woff2') format('woff2'),
  url('/assets/fonts/Philosopher/Philosopher-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Nunito Sans */

@font-face {
  font-family: 'Nunito Sans';
  src: url('/assets/fonts/NunitoSans/NunitoSans-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/NunitoSans/NunitoSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/assets/fonts/NunitoSans/NunitoSans-Bold.woff2') format('woff2'),
  url('/assets/fonts/NunitoSans/NunitoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/assets/fonts/NunitoSans/NunitoSans-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/NunitoSans/NunitoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/assets/fonts/NunitoSans/NunitoSans-SemiBoldItalic.woff2') format('woff2'),
  url('/assets/fonts/NunitoSans/NunitoSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/assets/fonts/NunitoSans/NunitoSans-Regular.woff2') format('woff2'),
  url('/assets/fonts/NunitoSans/NunitoSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/assets/fonts/NunitoSans/NunitoSans-Italic.woff2') format('woff2'),
  url('/assets/fonts/NunitoSans/NunitoSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* VP Pixel Simplified */

@font-face {
  font-family: 'VP Pixel';
  src: url('/assets/fonts/VPPixel/VPPixel-Simplified.woff2') format('woff2'),
  url('/assets/fonts/VPPixel/VPPixel-Simplified.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}