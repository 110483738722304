@import "src/assets/styles/responsive.scss";

.m-runesEnchantsPool {
  background-color: var(--color-background-dark-brown);

  @include mediaUpToSmallTablet {
    padding-bottom: 50px;
  }

  &__none {
    text-align: center;
    padding-top: 1em;
    font-family: 'Kufam';
    color: var(--color-element-grey);
  }

  &__enchant {
    position: relative;
    font-family: 'Nunito Sans';
    font-size: var(--font-normal);
    font-weight: 600;

    &:nth-child(2n) {
      background-color: #121110;
    }

    &:hover {
      .m-runesEnchantsPool__enchant-name {
        opacity: 0.8;
      }
    }

    &-name {
      font-family: 'Philosopher';
      font-size: var(--font-big);
      padding: 9px 14px;
      display: block;
      cursor: pointer;
    }

    // Collapsed
    &::after {
      content: '';
      background-image: url(/assets/images/icons/arrow_right_white.svg);
      background-repeat: no-repeat;
      width: 7px;
      height: 14px;
      opacity: 0.6;
      display: inline-block;
      position: absolute;
      right: 12px;
      top: 12px;
    }

    &.etype-legendary {
      color: var(--color-enchant-legendary);

      &::after {
        background-image: url(/assets/images/icons/arrow_right_orange.svg);
      }
    }

    // Expanded
    &.expanded {
      &::after {
        top: 14px;
        right: 9px;
        width: 14px;
        height: 7px;
      }

      &.etype-legendary {
        color: var(--color-enchant-legendary);

        &::after {
          background-image: url(/assets/images/icons/arrow_down_orange.svg);
        }
      }
    }

    &__data {
      color: var(--color-element-beige);
      margin-top: 10px;
      padding: 0px 18px 14px 18px;

      &-description {
        color: var(--color-element-light-orange);
        font-weight: normal;
        font-style: italic;

        &-amount {
          color: var(--color-element-light-beige);
          font-weight: bold;
        }

        &-skill {
          color: var(--color-element-yellow);
        }
      }
    }

    &__rolls {
      margin-top: 10px;
    }

    &__roll {
      list-style: none;
      display: flex;
      justify-content: space-between;

      &-header {
        color: var(--color-element-grey);
      }

      &-roll {
        font-style: normal;
      }
    }
  }
}